.location{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $body-font-color;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  &.promoted{
    padding-bottom: 0.75rem !important;
    .service-set{
      @include breakpoint(small only){   
        margin-bottom: 0.5rem;
      }
    }
  }
  @include breakpoint(medium){
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
  .loc-top{
    display: flex;
    @include breakpoint(small only){      
      flex-direction: column;      
    }
    .icon-wrap{
      @include breakpoint(small only){    
        flex-wrap: wrap;
        display: flex;
      }
      .off-wrap{
        @include breakpoint(small only){ 
          margin-bottom: 0.5rem;
          &:not(:last-of-type){
            margin-right: 9px;
          }
          .unavailable {
            text-decoration: line-through;
            opacity: 0.6;
          }
        }
      }
    }
    .loc-info{
      flex: 3 auto;
      .loc-name{
        display: flex;        
        flex-direction: column;
        margin-bottom: 0.5rem;
        @include breakpoint(medium){
          align-items: center; 
          flex-direction: row;          
        }
        h3{
          font-weight: 700;
          font-size: 1.5rem;
          margin-bottom: 0px;
          @include breakpoint(medium){
            font-size: 1.75rem;   
          }
        }       
        .miles-away{
          margin-left:0.5rem;
          font-weight: 600;
          @include breakpoint(small only){
            span { display: none; }
            margin: 0.5rem 0px;
          }
        }  
      }
      .loc-complex{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
      .loc-contact{
       margin-bottom: 1rem;
       display: block;   
      }      
    }
    .loc-offerings{
      legend{
        margin-bottom: 0.5rem;
        color: $body-font-color;
      }
      .service-set{
        margin-bottom: 1rem;
      }
    }
    .loc-actions{
      display: flex;
      flex-direction: column-reverse;      
      text-align: center;         
      align-items: self-end;
      .inner-ac{
        display: flex;
        flex-direction: column;        
        @include breakpoint(small only){
          margin-left: auto;
          margin-right: auto;
          width: auto;
        }      
        form{
          display: flex;
          flex-direction: column;
          @include breakpoint(small only){
            margin-bottom: 1rem;
          }
          .button{          
            @include breakpoint(small only){              
              margin-bottom: 0.5rem;
            }     
            @include breakpoint(medium){
              margin-bottom: 1rem;
            }     
          }          
        }    
      }
    }       
  }
  .loc-bottom{
    .loc-offerings{
      &.show-for-small-only{
        padding: 0.25rem 0px 0.5rem 0px;        
      }
      legend{
        margin-bottom: 0.5rem;
        color: $body-font-color;
      }
      .service-set{
        margin-bottom: 1rem;
        @include breakpoint(medium){
          margin-bottom: 0px;
        }        
        
        .icon-wrap{
          display: flex;     
          .off-wrap{
            &:not(:last-of-type){
              margin-right: 1rem;
            }
            input{
              margin-bottom: 0px;
            } 
            .fa-child{
              &:before{
                font-size: 18px;
              }
            }
            .unavailable {
              text-decoration: line-through;
              opacity: 0.6;
            }
          }              
        }
      }
    }
  }
}
.menu-dl{
  font-weight: 600;   
  display: block;    
  @include breakpoint(small only){                                
    margin-bottom: 0.5rem;   
  }       
  @include breakpoint(medium){
    margin-top: 1rem;
  }
}  