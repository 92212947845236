input[type='radio']{
  font-size: 14px;
  @include breakpoint(medium){
    margin-bottom: 0px;
  }
}
input[type='phone']{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  -webkit-box-shadow: inset 0 1px 2px rgba(74, 74, 74, 0.1);
  box-shadow: inset 0 1px 2px rgba(74, 74, 74, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #4a4a4a;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4a4a4a;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4a4a4a;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #4a4a4a;
}
.grid-container{
  &.cont-pad{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.chosen{
  color: #fdd60c;
  font-weight: 500;
  margin-bottom: 0px;
}
.company{
  .logo{
    @include breakpoint(small only){
      margin-bottom: 2rem;
    }
  }
  .catering{
    display: flex;
    align-items: center;
  }
}
.inquiry{
  display: flex;
  flex-direction: column;
  .small-12{
    margin-bottom: 1rem;
    h3{
      margin-bottom: 1rem;
    }
    .cell-value{
      flex: 13;
      #services{
        list-style: none;
        margin-left: 0px;
        li{
          margin-bottom: 1rem;
          .fa-truck{
            &:before{
              margin-right: 4px;
            }
          }
          .fa-child{
            &:before{
              font-size: 21px;
              margin-right: 8px;
            }
          }
          .fa-boxes{
            &:before{
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
.step{
  padding: 2rem 9rem 2rem 2rem;
  background: url(../img/dots.svg) #248386;
  background-repeat: no-repeat;
  background-position: right 134%;
  &.cont-pad{
    padding: 2rem 0px !important;
  }
  @include breakpoint(medium){
    background-position: right;

  }
  h2{
    line-height: 1;
    font-size: 2.5rem;
    @include breakpoint(medium){
      font-size: 3.25rem;
    }
    strong{
      line-height: 1;
      color: $ll-yellow;
      text-transform: uppercase;
    }
  }
  p{
    color: white;
  }
  a{
    color: #fdd60c;
  }
}
.contents{
  padding: 3.25rem 2rem 2rem 2rem;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}
.flex-even{
  @include breakpoint(medium){
    display: flex;
    justify-content: space-between;
  }
  .inner{
    width: 100%;
    @include breakpoint(medium){
      width: 47%;
    }
  }
  @include breakpoint(medium){
  .city {
      width: 40%;
    }
    .state {
      width: 30%;
    }
    .zip {
      width: 20%;
    }
  }
}
.addy{
  .address-set{
    legend{
      margin-bottom: .9rem;
    }
  }
}
.services{
  ul{
    list-style: none;
    margin-bottom: 0px;
    li{
      @include breakpoint(medium){
        display: inline-block;
      }
      label{
        font-weight: bold;
      }
    }
  }
}
.contact-meth{
  position: relative;
  .inner{
    @include breakpoint(small only){
      flex-direction: column;
    }
    &:not(:first-of-type){
      .wrap{
        &:nth-of-type(2){
          label.is-invalid-label{
            @include breakpoint(small only){
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
    .wrap{
      label{
        @include breakpoint(small only){
          position: relative;
          width: 100%;
        }
        input{
          width: auto;
          @include breakpoint(medium){
            margin-bottom: 0px !important;
          }
        }
      }

    }
  }
}
.contact-meth{
  .flex-even{
    &.pref-bottom{
      label{
        width: 100%;
        input{
          width: 100% !important;
        }
      }
    }
  }
}
label[for="phone-meth"].is-invalid-label,
label[for="budget1"].is-invalid-label{
  @include breakpoint(medium){
    margin-bottom: 1rem;
  }
}
label[for="inquiry_event_style_code_other"].is-invalid-label{
  @include breakpoint(small only){
    margin-bottom: 1rem;
  }
}
label[for="inquiry_customer_attributes_phone"]{
  @include breakpoint(medium){
    margin-right: 1rem;
  }
}
#inquiry_event_type_other,
#inquiry_event_style_other{
  display: none;
}
.contact-meth{
  @include breakpoint(medium){
  // display: flex;
  // align-items: center;
  }
  .wrap{
    &:last-of-type{
      @include breakpoint(small only){
        display: flex;
        flex-direction: column;
      }
      @include breakpoint(medium){
        position: relative;
        display: flex;
        flex-direction: row;
      }
      label{
        &:first-of-type{
          margin-bottom: 1rem;
        }
        .form-error{
          @include breakpoint(medium){
            margin-bottom: 0px !important;
            position: relative;
            top: 12px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  label{
    @include breakpoint(small only){
      display: flex;
    }
  }
}
.event-type{
  .radio-row{
    .inner{
      @include breakpoint(medium){
        grid-template-columns: 140px 140px;
        grid-gap: 0px 2rem;
        display: grid !important;
      }
      .wrap{
        label{
          @include breakpoint(medium){
            display: flex;
          }
        }
      }
    }
  }
}
.pref-top{
  .inner{
    flex-direction: column;
    .pref-wrap{
      display: flex;
      margin-bottom: 1rem;
      @include breakpoint(small only){
        flex-direction: column;
      }
      label{
        &.is-invalid-label{
          margin-bottom: 2rem;
          span.form-error{
            @include breakpoint(medium){
              top: 66px;
            }
          }
        }
        &:first-of-type{
          margin-right: 1rem;
        }
      }
      .pref-label{
        font-size: 0.8125rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        margin-right: 1rem;
        line-height: 100%;
        margin-bottom: 0.5rem;
        text-transform: capitalize !important;
      }
    }
    &:last-of-type{
      display: flex;
      .wrap{
        margin-top: 5px;
      }
    }
  }
}
span.break{
  display:block;
}
#pref-email,
#pref-phone{
  margin-bottom: 16px !important;
  @include breakpoint(medium){
    margin-bottom: 8px !important;
  }
}
.form-error.abs{
  position: absolute;
  left: 0px;
  top: 32px;
  &.email{
    @include breakpoint(small only){
      top: 58px;
    }
  }
  &.phone{
    @include breakpoint(small only){
      top: 58px;
    }
  }
}
.field-wrap{
  &:not(.flex-even){
    .inner{
      position: relative;
      @include breakpoint(small only){
        display: flex;
        flex-direction: column;
      }
      @include breakpoint(medium){
        display: flex;
      }
      .wrap{
        @include breakpoint(small only){
          display: flex;
          margin-bottom: 1rem;
          position: relative;
          input{
            width: auto !important;
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
}
:focus {
  outline: -webkit-focus-ring-color auto 5px !important;
}
label, legend{
  font-weight: bold;
  text-transform: uppercase;
}
label{
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  line-height: 100%;
  margin-bottom: 0.5rem;
  text-transform: capitalize !important;
}
.wrap{
  margin-bottom: 0.5rem;
  @include breakpoint(medium){
    display: flex;
    margin-right: 1rem;
  }
  label{
    font-weight: bold;
    @include breakpoint(small only){
      display: flex;
    }
  }
}
fieldset{
  margin-bottom: 2rem;
  legend{
    margin-bottom: 0.9rem;
    font-size: 1rem;
    text-transform: capitalize;
  }
  input{
    @include breakpoint(small only){
      width: 100%;
    }
  }
}
legend{
  margin-bottom: 0.9rem;
  font-size: 1rem;
  color: #248386;
  text-transform: uppercase;
  font-weight: 700;
}
.button{
  background-color: $button-bg;
  color: $button-text;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing:0.8px !important;
  font-size: 1rem;
  &:hover{
    background-color: $button-bg !important;
    color: $button-text;
  }
  &:focus{
    background-color: $button-bg !important;
    color: $button-text;
  }
}
.button--filled:hover {
  -webkit-box-shadow: 0 0 0 0 #B92538;
  box-shadow: 0 0 0 0 #B92538;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
}
.button--filled {
  border-top: 0;
  border-bottom: 0;
  background: #d13246;
  color: #fdd60c;
  -webkit-box-shadow: 0px 3px 0px 0px #B92538;
  box-shadow: 0px 3px 0px 0px #B92538;
  -webkit-transition: all ease-out 0.025s;
  transition: all ease-out 0.025s;
}

.button {
  border-top: 2px solid #d13246;
  border-bottom: 2px solid #d13246;
  padding: 0.5rem 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
}
label{
  .label-sp{
    margin-bottom: 0.5rem;
  }
}
.date-wrap{
  position: relative;
  button {
    position: absolute;
    right: 7px;
    top: 26px;
  }
}
.fa-calendar-alt{
  cursor: pointer;
  height: 14px;
  width:24px;
  position: absolute;
  right: 11px;
  top: 30px;
  &:before{
    font-family: 'Font Awesome\ 5 Free';
    content: '\f073';
    color: $ll-red;
    width: 100%;
    text-align: right;
    font-size: 1rem;
  }
}

.warning.callout {
  margin-bottom: 2rem;
  border-bottom: 5px solid #b38d1c;
  border-left: none;
  border-top: none;
  border-right: none;
  color: #665214;

  p {
    font-weight: 500;
  }
}

.alert.callout {
  margin-bottom:  2rem;
  border-bottom: 5px solid #cc4b37;
  border-left: none;
  border-top: none;
  border-right: none;
  color: #d13246;
  p {
    font-weight: 600;
    @include breakpoint(medium){
      text-align: center;
    }
    display: flex;
    align-items: center;
    span{
      &:first-of-type{
        margin-left: auto;
        margin-right: auto;
      }
      &:nth-of-type(2){
        margin-right: auto;
        color:#B6412F;
      }
      &:before{
        font-size: 1.5rem;
        margin-left: 0rem;
        color: #cc4b37;
        margin-right: 0.75rem;
        font-weight: 700;
        @include breakpoint(medium){
          margin-right: 0.5rem;
        }
      }
    }
  }
  &#warning_explanation {
    p {
      display: block;
      text-align: left;
    }
  }
}
form{
  label{
    margin-bottom: 0px;
  }
}
span.form-error{
  margin-bottom: 1rem;
  @include breakpoint(medium){
    margin-bottom: 0px !important;
  }
  text-transform: capitalize;
  font-weight: 700;
}
.far{
  font-weight: 900 !important;
}
.fas{
  font-weight: 900 !important;
}


//Step 1 Form specific styles
#event-info{
  .guest-date{
    .inner:last-of-type{
      label{
        .form-error{
          @include breakpoint(small only){
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
}
#event-info{
  fieldset{
    .form-error{
      @include breakpoint(medium){
        margin-bottom: 1rem !important;
      }
    }
  }
}
#event-info{
  .services-set{
    margin-bottom: 2.2rem;
  }
}
.other-set{
  &.expanded{
    margin-bottom: 4rem;
  }
  .wrap{
    @include breakpoint(medium){
      &:first-of-type{
        margin-bottom: 1rem;
      }
      &:nth-of-type(2){
        margin-bottom: 1rem;
      }
    }
  }
}
.event-style{
  .radio-row{
    margin-bottom: 0px !important;
  }
}
.radio-row{
  &:first-of-type{
    @include breakpoint(medium){
      margin-bottom: 0.5rem;
    }
  }
  input{
    margin-bottom: 0px;
  }
  .other-group{
    display: inline;
    position: relative;
    @include breakpoint(small only){
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .event-other{
      display: none;
      width: 200px;
      margin-top: 16px;
      &.is-invalid-label{
        position: relative;
        .form-error.abs{
          top: 55px;
        }
      }
    }
  }
  .form-error{
    @include breakpoint(medium){
      margin-bottom: 0px;
    }
  }
}
.budget-set{
  .inner{
    .wrap{
      .is-invalid-label{
        @include breakpoint(medium){
          margin-bottom: 1rem;
        }
      }
    }
  }
}
fieldset.notes{
  textarea{
    height: 150px;
  }
}
.service-row{
  display: flex;
  @include breakpoint(small only){
    flex-direction: column;
  }
  .wrap{
    @include breakpoint(small only){
      display: flex;
    }
    input{
      @include breakpoint(small only){
        width: auto;
      }
    }
    .form-error.show-for-medium{
      position: absolute;
    }
    .form-error.show-for-small-only{
      @include breakpoint(small only){
        margin-top: 0.25rem;
      }
    }
  }
}
.phone-input{
  display: none;
  width: 185px;
  height: 2.4375rem;
  font-size: 1rem;
}
.email-input{
  display: none;
}

.submit{
  text-align: right;
}
#event-info{
  .guest-date{
    .form-error{
      @include breakpoint(small only){
        margin-bottom: 1.75rem !important;
      }
    }
  }
}