body{
  letter-spacing: 0.0625em;
}
a {
  text-decoration: underline;
}
.num-sizing{
  font-size: 3rem;
  @include breakpoint(large){
    font-size: 3.75rem;
  }  
}
.flex-med{
  display: flex;  
}
.fas span {
  font-family: $body-font-family;
  font-weight: 500;  
}
.fa-question.has-tip{
  border: 0px;
  position: relative;
  top: -2px;
  &:before{
    content: "\f128";
    color: #d13246;
    border: 1px solid #d13246;
    border-radius: 50px;   
    padding: 0.2rem;
    font-size: 0.6rem;
  }
}
legend .fa-question.has-tip::before {
  padding: 0.2rem 0.27rem;
}
.services-list{
  display: flex;
  .services{
    list-style: none;
  }  
}
.promoted-label{  
  font-family: $body-font-family;
  display: flex;  
  font-weight: 500;
  font-size: 0.9rem;
  @include breakpoint(small only){
    margin-bottom: 1.5rem;
  }
  @include breakpoint(medium){
    margin-top: 1.5rem;
  }  
  
  .fas{
    font-size: 10px;
    font-weight: 600;
  }
}
#logo{  
  width: 100%;
  @include breakpoint(medium){
    max-width: 145px;
  }
}
.breadcrumb{
  margin-bottom: 2rem;
  a{
    font-weight: 600;
  }
}
.intro{
  margin-bottom: 1rem;
  margin-top: 0rem;
  &.accept{
    margin-bottom: 2rem;
  }
}
.loc-stack{
  display: block;
  margin-bottom: 0px; 
  &.phone{
    margin-top: 8px;
  } 
}
.actions{
  margin-top: 2rem;
}
$progress-indicator-complete: $ll-red;
$progress-indicator-current: $ll-blue;
$progress-indicator-incomplete: $light-gray;
$progress-indicator-step-size: 35px;
$progress-indicator-width: 100%;

.progress-wrap{
  margin-bottom: 2rem;
  .progress-indicator {
    list-style: none;
    width: $progress-indicator-width;
    margin: 0 auto;
    padding: 0;
    display: table;
    table-layout: fixed;

    > li {
      position: relative;
      display: table-cell;
      text-align: center;
      font-size: 1.5em;

      span {
        position: absolute;
        color: $light-gray;
        transform: translateX(-50%);
        font-weight: 600;
        font-size: rem-calc(14);
        letter-spacing: 0.05px;
        text-transform: uppercase;
      }

      &::before {
        content: attr(data-step);
        display: block;
        margin: 0 auto;
        background: $progress-indicator-incomplete;
        width: 40px;
        height: 40px;
        text-align: center;
        margin-bottom: 0.25em;
        line-height: $progress-indicator-step-size;
        border-radius: 100%;
        position: relative;
        z-index: 1000;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        background: $progress-indicator-incomplete;
        width: 100%;
        height: 2px;
        top: 47%;
        transform: translateY(-100%);
        left: 50%;
        margin-left: 1.5em\9;
        z-index: 0;
      }

      &:last-child:after {
        display: none;
      }

      &.is-complete {

        &::before{
          background-color: white;
          border: 1px solid $ll-red;
          color: $ll-red;
          font-weight: 500;    
        }
        &::after {
          color: $white;
          background: $ll-red;
        }
        a{
          position: absolute;
          top: 0px;
          right: 50%;
          margin-right: 0px;
          z-index: 9999; 
          .clear-step{
            color: transparent;            
          }         
        }
        span {
          color: $progress-indicator-complete;
        }
      }

      &.is-current {
        color: $progress-indicator-current;

        &::before {
          color: white;
          background: $ll-red;
          border: 1px solid $ll-red;
          font-weight: 600;
        }
        span {
          color: $progress-indicator-current;
        }
      }
    }
    li{
      &:last-of-type{
        &:before{
          height: 42px;          
        }
      }
    }
  }  
}


